import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import config from "../../config/main.config";
import {jumpToTop, MAX_MOBILE_WIDTH, UseWindowSize} from "../../helper/util";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import EditTextComponent from "../../components/editText/EditTextComponent";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";
import EditImageComponent from "../../components/editImage/EditImageComponent";
import history from "../../helper/browserHistory";
import i18n from "../../i18n";


const StiftungPage = () => {
    let applicationWidth = UseWindowSize().width;

    useEffect(() => {
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);
    const namespace = 'stiftung'
    return (
        <div className={'ui grid'}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'Stifung', url: '/stiftung'}]}/>
            </div>
            <div className={"top-image"}
                 style={{backgroundImage: "url(" + config.BASE_URL_IMAGES + "StiftungTopImage.webp)"}}/>
            <div className={'row centered'} style={{marginBottom: '200px'}}>
                <div className={'twelve wide column page-content-wrapper'}
                     style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                         position: 'absolute',
                         bottom: '-150px',
                         padding: 0
                     } : {}}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position'} style={{float: 'left', minWidth: '50%', zIndex: '1'}}>
                            <Card.Header className={'card-header'} s>
                                <h1 style={{paddingBottom: "45px"}}><EditTextComponent id={`${namespace}-card-1-header`}
                                                                                       namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0', padding: '1rem 0 0 0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-card-1-text`}
                                                       namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-1-header`} namespace={namespace}/>
                        </h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-card-1-text`}
                                                   namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered page-content-wrapper'}
                 style={applicationWidth <= MAX_MOBILE_WIDTH ? {rowGap: '4rem', paddingTop: '4rem'} : {}}>
                <div className={'eight wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace}/></p>
                    <button className={'button-1'}
                            onClick={() => history.push('/' + i18n.language + '/ambulant')}>AMBULANTE
                        BETREUUNG >
                    </button>
                </div>
                <div className={'eight wide column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-1`}/>
                </div>
                <div className={'eight wide column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-2`}/>
                </div>
                <div className={'eight wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-2`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-2`} namespace={namespace}/></p>
                    <button className={'button-1'} onClick={() => {
                        history.push('/' + i18n.language.replace('_', '-') + '/stationär')
                    }}>STATIONÄRE PFLEGE >
                    </button>
                </div>
                <div className={'eight wide computer twelve wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-3`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-3`} namespace={namespace}/></p>
                    <button className={'button-1'}>ZU DEN ANGEBOTEN ></button>
                </div>
                <div className={'eight wide column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-3`}/>
                </div>
            </div>
            <div style={{display: 'flex', width: '100%', position: 'relative', bottom: '-140px'}}>
                <svg width='1000' height='8' style={{margin: 'auto'}}>
                    <line x1='0' y1='0' x2='100%' y2='0' stroke='#65b32e' strokeWidth='8'/>
                </svg>
            </div>
            <div className={'row centered'} style={{marginTop: '6rem', marginBottom: '2rem'}}>
                <div className={'column'} style={{display: 'flex'}}>
                    <h1 style={{
                        color: '#65b32e',
                        textAlign: 'center',
                        marginTop: '4rem',
                        marginBottom: '4rem',
                        margin: 'auto',
                        width: '800px',
                        background: 'white'
                    }}>Sprechen Sie mit uns</h1>
                </div>
            </div>
        </div>
    )
}

StiftungPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'stiftung').then((text) => {
        return text;
    })
}

export default withTranslation()(StiftungPage);